@import './../../sass/fonts.scss';
@import './../../sass/colors.scss';

.main-nav {
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: #f6f5f3;

  .navbar {
    padding: 0;

    .navbar-brand {
      img {
        max-width: 200px;
      }
    }

    .navbar-kids-brand {
      img {
        max-width: 150px;
      }
    }

    .navbar-kids-brand {
      img {
        max-width: 100px;
      }
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $color-black;
          font-size: 15px;
          font-weight: 600;
          padding: 0;
          margin: 10px 30px;

          &:hover {
            color: #C5A47E;
          }
        }
      }
    }
  }
}

// Mobile Responsive
@media (max-width: 575px) {
  .main-nav {
    padding: 10px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .navbar {
      display: flex;
      justify-content: space-between;

      .navbar-brand {
        margin-right: 20px; // Adjust margin here to increase distance between logo and contact button
      }

      .navbar-nav {
        .nav-item {
          .nav-link {
            margin: 10px 0px;
          }
        }
      }
    }
  }

  .language-text-res {
    margin-top: 1.2rem !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .main-nav {
    padding: 10px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .navbar {
      display: flex;
      justify-content: space-between;

      .navbar-brand {
        margin-right: 20px; // Adjust margin here to increase distance between logo and contact button
      }

      .navbar-nav {
        .nav-item {
          .nav-link {
            margin: 10px 0px;
          }
        }
      }
    }
  }

  .language-text-res {
    margin-top: 1.2rem !important;
  }
}

// Medium Devices Responsive
@media (min-width: 768px) and (max-width: 991px) {
  .main-nav {
    padding: 15px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .navbar {
      display: flex;
      justify-content: space-between;

      .navbar-brand {
        margin-right: 20px; // Adjust margin here to increase distance between logo and contact button
      }

      .navbar-nav {
        .nav-item {
          .nav-link {
            margin: 10px 0px;
          }
        }
      }
    }
  }

  .language-text-res {
    margin-top: 1.2rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-nav {
    padding: 20px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .navbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            margin: 10px 15px;
          }
        }
      }
    }
  }

  .language-text-res {
    margin-top: 1.2rem !important;
  }
}

.language-text {
  color: $color-black;
  font-size: 15px;
  font-weight: 600;
  padding: 0;
  margin-top: 0.3rem;

  &:hover {
    color: #C5A47E;
  }
}