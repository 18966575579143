.service-banner-section {
    margin-top: 130px;

    .service-banner-text {
        h2 {
            font-size: 60px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 50px;
        }
    }

    .service-banner-img {
        text-align: center;
        padding-top: 80px;
        margin-bottom: -100px;

        img {
            width: 550px;
            height: 600px;
            object-fit: cover;
            border-radius: 20px; // Kenarları yuvarlak yapar
        }
    }
}

// Mobile Responsive
@media (max-width: 575px) {
    .service-banner-section {
        margin-top: 70px;
        padding: 30px 0 50px;

        .service-banner-text {
            text-align: center;

            h2 {
                font-size: 40px;
            }

            p {
                margin-bottom: 20px;
            }
        }

        .service-banner-img {
            text-align: center;
            padding-top: 20px;
            margin-bottom: 0;

            img {
                width: 100%; // Mobilde ekran genişliğine göre ayarlar
                height: auto;
                border-radius: 15px; // Mobilde daha az yuvarlaklık
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .service-banner-section {
        margin-top: 70px;
        padding: 30px 0 50px;

        .service-banner-text {
            text-align: center;

            h2 {
                font-size: 40px;
            }

            p {
                margin-bottom: 20px;
            }
        }

        .service-banner-img {
            text-align: center;
            padding-top: 20px;
            margin-bottom: 0;

            img {
                width: 450px; // Orta boy cihazlar için
                height: auto;
                border-radius: 18px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .service-banner-section {
        margin-top: 90px;
        padding: 30px 0 50px;

        .service-banner-text {
            h2 {
                font-size: 40px;
            }

            p {
                margin-bottom: 20px;
            }
        }

        .service-banner-img {
            text-align: center;
            padding-top: 20px;
            margin-bottom: 0;

            img {
                width: 500px;
                height: auto;
                border-radius: 20px;
            }
        }
    }
}