@import '../../sass/colors.scss';

.popup-card {
  position: fixed;
  top: 7rem;
  right: 1rem;
  width: 10rem;
  z-index: 1000;
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 1;
  transform: translateX(0);

  &.hidden {
    opacity: 0;
    transform: translateX(100%);
  }

  .close-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.7rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: #FBDBC0;
    transition: color 0.3s ease;

    &:hover {
      color: $color-orange;
    }
  }
}

.reopen-button {
  position: fixed;
  top: 7.2rem;
  right: 1rem;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  color: #FBDBC0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;

  &:hover {
    color: $color-orange;
  }
}
