@import './sass/fonts.scss';
@import './sass/colors.scss';

// Common CSS
body {
  padding: 0;
  margin: 0;
  font-family: $body-font;
}

img {
  max-width: 100%;
}

p {
  color: $color-gray;
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-black;
  margin-bottom: 0;
}

.theme-btn {
  a {
    background: #C5A47E;
    color: $color-white;
    padding: 14px 20px;
    text-decoration: none;
    border-radius: 15px;
    transition: .3s;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);

    &:hover {
      background: #ffdec3;
      color: $color-white;
      border: 1px solid #ffdec3;
    }
  }
}

.communication-btn {
  a {
    background: #c5a47e !important;
    color: $color-white !important;

    &:hover {
      background: #ffdec3 !important;
      color: $color-white !important;
      border: 1px solid #ffdec3 !important;
    }
  }
}

.section-bg {
  background-color: #dec3ad;
}

.section-common {
  border-radius: 30px;
  margin: 0 25px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

// Mobile Responsive
@media (max-width: 575px) {
  .section-common {
    margin: 0 15px;
    border-radius: 20px;
  }

  p {
    font-size: 16px;
  }

  .pt-100 {
    padding-top: 50px;
  }

  .pb-70 {
    padding-bottom: 20px;
  }

  img {
    margin-bottom: 1rem;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .section-common {
    margin: 0 15px;
    border-radius: 20px;
  }

  p {
    font-size: 16px;
  }

  .pt-100 {
    padding-top: 50px;
  }

  .pb-70 {
    padding-bottom: 20px;
  }

  img {
    margin-bottom: 1rem;
  }
}

// Medium Responsive
@media (min-width: 758px) and (max-width: 991px) {
  .pt-100 {
    padding-top: 70px;
  }

  .pb-70 {
    padding-bottom: 40px;
  }

  img {
    margin-bottom: 1rem;
  }
}