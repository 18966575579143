.testimoni-slider {
    .slick-arrow {
        display: none !important;
    }
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    font-size: 24px;
    color: #333;

    &.prev {
        left: -50px;
    }

    &.next {
        right: -50px;
    }

    &:hover {
        color: #000;
    }
}