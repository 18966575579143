@import '../../sass/colors.scss';

.single-service {
    width: 100%;
    padding: 50px 10px 0;
    margin-bottom: 30px;
}

.service-card {
    text-align: center;
    background: $color-white;
    border-radius: 32px;
    padding: 100px 30px 30px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between; // İçerik dikey olarak eşit aralıkta dağıtılır
    min-height: 450px; // Kartların minimum yüksekliğini ayarla
    max-height: 500px; // İstenirse bir maksimum yükseklik de belirlenebilir

    .service-img {
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 350px;
        height: 200px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: none;
            border-radius: 16px;
            display: block;
        }
    }

    .service-text {
        flex-grow: 1; // İçeriğin esnemesine izin verir
        padding-top: 140px;

        h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
            margin-bottom: 25px;
        }
    }

    .service-rating {
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            color: #FF8C4B;
            font-size: 25px;
            margin: 0 2px;

            &:last-child {
                color: #C4C4C4;
            }
        }
    }
}