@import '../../sass/colors.scss';

.team-section {
  margin-bottom: 60px;

  .team-card {
    text-align: center;
    position: relative;
    margin-bottom: 30px;

    .team-img {
      margin-bottom: 20px;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 370px; // Tüm resimleri aynı yükseklikte tutar
        object-fit: cover; // Resimleri orantılı şekilde kırpar
        border-radius: 10px;
        transition: transform 0.3s ease-in-out;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #DBEFFA;
        content: '';
        z-index: -1;
        border-radius: 10px;
        opacity: 0;
        transition: opacity 1s ease-in-out;
      }

      .team-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        border-radius: 10px;
        padding: 10px;
        text-align: center;
        font-size: 12px;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

        p {
          font-size: 14px; // Hover yazı boyutunu küçülttüm
        }
      }

      &:hover {
        img {
          transform: scale(1);
        }

        .team-overlay {
          opacity: 1;
          transform: scale(1);
        }

        &::before {
          opacity: 1;
        }
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .upper-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;

    .team-card {
      margin: 0 15px 30px;
      flex-basis: 45%;
    }
  }

  .lower-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .team-card {
      margin: 0 15px 30px;
      flex-basis: 22%;
    }
  }

  @media (max-width: 575px) {
    .team-card {
      margin-bottom: 30px;
      margin-left: 0;
      margin-right: 0;
    }

    .upper-row {
      justify-content: space-between;

      .team-card {
        flex-basis: 48%;
        margin-bottom: 30px;
      }
    }

    .lower-row {
      justify-content: space-between;

      .team-card {
        flex-basis: 48%;
        margin-bottom: 30px;
      }
    }
  }
}