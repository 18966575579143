@import '../../sass/colors.scss';

.service-cards {
  padding: 80px 0;

  .service-grid {
    display: grid;
    gap: 5px;
    justify-content: center;

    // Bilgisayar ve Büyük Ekranlar: Her satırda 3 kart olacak
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    // Tablet: Her satırda 2 kart olacak, toplamda 2 satır olacak
    @media (max-width: 1024px) and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    // Mobil: Her satırda 1 kart olacak, toplamda 2 satır olacak
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .service-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px;
    background: $color-white;
    border-radius: 16px;
    overflow: hidden;
    min-height: 465px;
    max-height: 550px;
    box-sizing: border-box;
    border: 1px solid #d1d1d1;
    transition: box-shadow 0.3s ease-in-out;
    margin-bottom: 1px;

    // Tablet: Her satırda 2 kart olacak, toplamda 2 satır olacak
    @media (max-width: 1024px) and (min-width: 768px) {
      min-height: 550px;
    }

    // Mobil: Her satırda 1 kart olacak, toplamda 2 satır olacak
    @media (max-width: 767px) {
      min-height: 465px;
    }

    &:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .service-img {
      position: absolute;
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
      width: 350px;
      height: 200px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: none;
        border-radius: 16px;
        display: block;
      }
    }

    .service-text {
      padding-top: 230px;
      flex-grow: 1;

      h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        margin-bottom: 25px;
      }
    }
  }

  .show-more-btn {
    color: #583FBC;
    text-decoration: none;
    font-weight: 700;
    background: #F6F5F3;
    padding: 15px 30px;
    display: inline-flex;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    border: 2px solid #583FBC;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    margin-top: 20px;

    &:hover {
      background-color: #583FBC;
      color: white;
      box-shadow: 0 6px 12px rgba(88, 63, 188, 0.3);
    }
  }
}

.services-link {
  margin: 50px 0 30px;

  a {
    color: $color-orange;
    text-decoration: none;
    font-weight: 700;
    background: rgba(188, 121, 63, 0.12);
    padding: 15px 25px;
    display: inline-block;
    border-radius: 15px;

    svg {
      margin-left: 10px;
      font-size: 20px;
      top: -2px;
      position: relative;
    }
  }
}