@import '../../sass/colors.scss';

.single-testimoni {
    width: 100%;
    padding: 0px 10px 0;
    margin-bottom: 30px;
}

.testimonial-card {
    text-align: center;
    background: $color-white;
    border-radius: 32px;
    padding: 70px 30px 30px;
    position: relative;

    .testimonial-img {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        margin: auto;

        img {
            border: 8px solid #D9EBFA;
            border-radius: 50%;
            display: inline-block;
        }
    }

    .testimonial-text {
        h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 15px;
        }

        p {
            font-size: 14px;
            margin-bottom: 45px;
        }
    }

    .testimonial-rating {
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            margin: 0 2px;
        }

        .filled {
            color: #FF8C4B !important;
            /* Orange color for filled stars */
            font-size: 30px !important;
            /* Larger size for filled stars */
        }

        .outlined {
            color: #C4C4C4 !important;
            /* Gray color for outlined stars */
            font-size: 25px !important;
            /* Default size for outlined stars */
        }
    }
}