.image-section {
    display: flex;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    height: auto;
    justify-content: flex-start; // Resimleri sola hizaladık
    align-items: center;
    flex-wrap: nowrap; // Resimler taşmasın diye sabit bir sıra sağlıyoruz


    .image-item {
        object-fit: cover; // Görsellerin alanı doldurmasını sağlar
        transition: transform 0.3s ease-in-out;

        &:hover {
            transform: scale(1.05); // Hover durumunda resmin biraz büyümesi
        }
    }

    // Tablet ve bilgisayarlar için uyumluluk
    @media (min-width: 769px) {
        .image-item {
            width: 33.33%; // Üç resim için her birinin genişliği %33 olacak
            height: 100%; // Yükseklik, container ile aynı olacak
        }
    }

    // Mobil uyumluluk
    @media (max-width: 768px) {
        flex-direction: column; // Fotoğraflar üst üste gelecek
        height: auto; // Esnek yükseklik
        padding: 0; // Ekran kenarlarından boşluk kaldırılacak

        .image-item {
            width: 100%; // Fotoğraflar tam ekran genişliğini kaplayacak
            height: auto; // Yükseklik fotoğrafın orantısına göre ayarlanacak
            margin: 0; // Fotoğraflar arasında boşluk olmayacak
        }
    }
}