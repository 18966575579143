.about-section {
  height: 750px;
  margin-top: 2rem;

  .about-banner-text {
    h2 {
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 50px;
    }

    .pet-friendly-img {
      img {
        width: 150px; // Küçültüldü
        height: auto;
        margin-top: 20px;
        margin-left: 420px; // Daha sağa kaydırıldı
      }
    }
  }

  .about-banner-img {
    text-align: center;
    position: relative;

    img {
      width: 290px;
      height: 420px; // Yükseklik 420 olarak güncellendi
      margin: 0 10px;
      border-radius: 20px; // Köşeleri yuvarlama

      &:nth-child(2) {
        margin-top: 120px;
      }
    }

    .pattern {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
      z-index: -1;
    }
  }
}

// Mobile Responsive
@media (max-width: 575px) {
  .about-section {
    text-align: center;
    height: 100%;
    padding-top: 70px;

    .about-banner-text {
      margin-bottom: 20px;

      h2 {
        font-size: 40px;
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 25px;
      }

      .pet-friendly-img {
        img {
          width: 120px; // Mobile için daha küçük
          height: auto;
          margin-top: 15px;
          margin-left: 30px; // Daha sağa kaydırıldı
        }
      }
    }

    .about-banner-img {
      img {
        &:nth-child(2) {
          margin-top: 10px;
          margin-bottom: 10px; // Alt boşluk
        }
      }
    }
  }
}

// Tablet Responsive
@media (min-width: 576px) and (max-width: 767px) {
  .about-section {
    text-align: center;
    height: 100%;
    padding-top: 70px;

    .about-banner-text {
      margin-bottom: 20px;

      h2 {
        font-size: 40px;
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 25px;
      }

      .pet-friendly-img {
        img {
          width: 130px; // Tablet için boyutlandırıldı
          height: auto;
          margin-top: 18px;
          margin-left: 40px; // Daha sağa kaydırıldı
        }
      }
    }

    .about-banner-img {
      img {
        width: 290px;
        height: 420px; // Yükseklik 420 olarak güncellendi
      }
    }
  }
}

// Medium Responsive
@media (min-width: 768px) and (max-width: 991px) {
  .about-section {
    text-align: center;
    height: 100%;
    padding: 100px 0;

    .about-banner-text {
      h2 {
        font-size: 40px;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        margin-bottom: 20px;
      }

      .pet-friendly-img {
        img {
          width: 140px; // Medium için boyutlandırıldı
          height: auto;
          margin-top: 18px;
          margin-left: 40px; // Daha sağa kaydırıldı
        }
      }
    }

    .about-banner-img {
      img {
        width: 290px;
        height: 420px; // Yükseklik 420 olarak güncellendi
      }
    }
  }
}

// Large Responsive
@media (min-width: 992px) and (max-width: 1199px) {
  .about-section {
    height: 100%;
    padding: 100px 0;

    .about-banner-text {
      .pet-friendly-img {
        img {
          width: 150px; // Large ekranlar için boyutlandırıldı
          height: auto;
          margin-top: 20px;
          margin-left: 50px; // Daha sağa kaydırıldı
        }
      }
    }

    .about-banner-img {
      img {
        width: 290px;
        height: 420px; // Yükseklik 420 olarak güncellendi
      }
    }
  }
}