@import '../../sass/colors.scss';

.contact-info {
  margin-top: 80px;

  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .info-icon {
      font-size: 24px;
      margin-right: 15px;
      color: $color-orange;
    }

    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: $color-orange;
    }

    p {
      margin: 0;
      font-size: 14px;
      color: #555;
    }
  }
}